.contacto-tablet-container {
  width: 100%;
  height: auto;

  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}

/* ////////////////////////////////////////////////// CONTACT ////////////////////////////////////////////////// */

.contacto-tablet-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 11vh;
  padding: 150px 32px;
  max-width: 744px;
  align-self: center;
  gap: 100px;
  width: 100%;
  height: auto;
}

.contacto-tablet-text-box {
  display: flex;
  flex-direction: column;
  align-content: center;

  gap: 20px;
}

.contacto-tablet-title {
  width: auto;
  height: auto;

  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
}

.contacto-tablet-text {
  width: auto;
  height: auto;

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.contacto-tablet-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 32px;
  width: auto;
  height: auto;
}

.contacto-tablet-form-input-row {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */

  gap: 24px;
  width: 100%;
  height: auto;
}

.contacto-tablet-form-input-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 24px;
  width: 300px;
  height: auto;
}

.contacto-tablet-form-input-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 25px;
  width: auto;
  height: auto;
}

.contacto-tablet-form-label {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.contacto-tablet-form-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 30px;
  width: 100%;
  height: auto;

  background: #1E1E1E;

  border: 1px solid #D0D0D0;
  border-radius: 50px;
}

.contacto-tablet-form-input[type=text]:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-tablet-form-input-success {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 30px;
  width: 100%;
  height: auto;

  background: #1E1E1E;

  border: 1px solid #45D7A2;
  border-radius: 50px;
  outline: none;
}

.contacto-tablet-form-input-success:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-tablet-form-input-error {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 30px;
  width: 100%;
  height: auto;

  background: #1E1E1E;

  border: 1px solid #FF2159;
  border-radius: 50px;
  outline: none;
}

.contacto-tablet-form-input-error:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-tablet-textarea-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 25px;
  width: 100%;
  height: auto;
}

.contacto-tablet-form-textarea {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;

  background: #1E1E1E;
  border: 1px solid #D0D0D0;
  border-radius: 20px;
  outline: none;
}

.contacto-tablet-form-textarea:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-tablet-form-textarea-success {
  box-sizing: border-box;
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;
  
  background: #1E1E1E;
  border: 1px solid #45D7A2;
  border-radius: 20px;
  outline: none;
}

.contacto-tablet-form-textarea-success:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-tablet-form-textarea-error {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;

  background: #1E1E1E;
  border: 1px solid #FF2159;
  border-radius: 20px;
  outline: none;
}

.contacto-tablet-form-textarea-error:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-tablet-btn-box {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 162px;
  width: auto;
  height: 68px;
}

.contacto-tablet-btn {
  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  cursor: pointer;

  padding: 20px 36px;
  gap: 8px;

  width: auto;
  height: 68px;

  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
  transition: background 0.25s ease-in;
}

.contacto-tablet-btn:hover {
  background: #EC632B;
  border: 1px solid #EC632B;
  transition: background 0.25s ease-in;
}

.contacto-tablet-btn:hover .contacto-tablet-btn-text {
  color: #FFF;
}

.contacto-tablet-btn-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

.contacto-tablet-btn:disabled {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;

  padding: 20px 36px;
  gap: 8px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  width: 100%;
  height: 68px;
  color: var(--color-dark-black-20, #4D4D4D); 

  border-radius: 40px;
  border: 1px solid var(--color-dark-black-40, #3C3C3C);
  background: var(--color-dark-black-60, #1E1E1E);
}

.contacto-tablet-btn:disabled:hover span {
  color: #000; 
}