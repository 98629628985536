/* ////////////////////////////////////////////////// FOOTER ////////////////////////////////////////////////// */

.footer-section {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  align-self: center;
  padding: 57px 124px;
  gap: 75px;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer-img-box {
  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer-logo {
  width: auto;
  height: auto;
}

.footer-pages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 40px;

  width: auto;
  height: 28px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.footer-link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: auto;
  height: 28px;

  flex: none;
  flex-grow: 0;
}

.footer-link-01 {
  order: 0;
}

.footer-link-02 {
  order: 1;
}

.footer-link-03 {
  order: 2;
}

.footer-link-04 {
  order: 3;
}

.footer-link-05 {
  order: 4;
}

.footer-lang-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: auto;
  height: auto;

  flex: none;
  flex-grow: 0;
  order: 2;
}

.footer-social-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 36px;
  height: 36px;

  flex: none;
  order: 3;
  flex-grow: 0;
}

.footer-social-img {
  width: auto;
  height: auto;
}

.footer-copyright-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 30px;

  width: auto;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.footer-divider {
  width: 100%;
  height: auto;

  border: 1px solid #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer-copyright-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;

  flex: none;
  order: 1;
  flex-grow: 0;
}