.dynamic-card-mobile-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  max-width: 570px;
  padding: 30px;
  border-radius: 30px;
  max-height: 140px;
  transition: background 0.5s, border 0.5s, max-height 0.5s;
}

.dynamic-card-content {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.dynamic-card-mobile-icon {
  width: 44px;
  height: 44px;
  align-self: flex-start;
}

/* ////////////////////////////////////////////////// ACIVE ////////////////////////////////////////////////// */

.dynamic-card-mobile-img {
  width: 100%;
  height: auto;
}

.dynamic-card-mobile-box.dynamic-card-mobile-box-active-green {
  max-height: 250px;
}

.dynamic-card-mobile-box.dynamic-card-mobile-box-active-orange {
  max-height: 250px;
}

.dynamic-card-mobile-box.dynamic-card-mobile-box-active-blue {
  max-height: 250px;
}

.dynamic-card-mobile-box.dynamic-card-mobile-box-active-yellow {
  max-height: 250px;
}

.dynamic-card-mobile-box-active-green {
  box-sizing: border-box;
  background: #45D7A2;
  border: 1px solid #45D7A2;
}

.dynamic-card-mobile-box-active-orange {
  box-sizing: border-box;
  background: #EC632B;
  border: 1px solid #EC632B;
}

.dynamic-card-mobile-box-active-blue {
  box-sizing: border-box;
  background: #465DFF;
  border: 1px solid #465DFF;
}

.dynamic-card-mobile-box-active-yellow {
  box-sizing: border-box;
  background: #FEC40B;
  border: 1px solid #FEC40B;
}

.dynamic-card-mobile-text-active {
  align-self: center;
  color: #000000;
  text-align: start;
}

.dynamic-card-mobile-text-active strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #000000;
}

.dynamic-card-mobile-text-active span {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
}

.dynamic-card-arrow-img-active {
  width: 24px;
  height: auto;
  align-self: flex-start;
}

/* ////////////////////////////////////////////////// DEACIVE ////////////////////////////////////////////////// */

.dynamic-card-mobile-box-deactive {
  box-sizing: border-box;
  background: #1E1E1E;
  border: 1px solid #FFFFFF;
}

.dynamic-card-mobile-text-deactive strong {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: #FFFFFF;
  text-align: start;
  align-self: center;
}

.dynamic-card-arrow-img {
  width: 24px;
  height: auto;
  align-self: center;
}

/* ////////////////////////////////////////////////// MEDIA ////////////////////////////////////////////////// */

.rotateOn {
  -webkit-animation: rotateOn 0.5s;
  animation: rotateOn 0.5s;
  transform: rotate(180deg);
  -webkit-filter: invert(1);
  filter: invert(1);
}

@-webkit-keyframes rotateOn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateOn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.rotateOff {
  -webkit-animation: rotateOff 0.5s;
  animation: rotateOff 0.5s;
  transform: rotate(0deg);
  -webkit-filter: invert(0);
  filter: invert(0);
}

@-webkit-keyframes rotateOff {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotateOff {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.fade {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}