.quienes-somos-mobile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-height: 100vh;
}

/* ////////////////////////////////////////////////// OUR MISSION ////////////////////////////////////////////////// */

.our-mission-mobile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 360px;
  align-self: center;
  padding: 80px 10px;
  margin-top: 10vh;

  width: 100%;
  height: auto;
}

.our-mission-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 50px;

  width: auto;
  height: auto;
}

.our-mission-mobile-title {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 35px;
  line-height: 44px;

  text-align: center;
}

.our-mission-mobile-text {
  width: auto;
  height: auto;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;

  text-align: center;
}

/* ////////////////////////////////////////////////// WHO ARE WE ////////////////////////////////////////////////// */

.who-are-we-mobile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  align-self: center;
  padding: 80px 10px;

  width: 100%;
  height: auto;
}

.who-are-we-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 50px;

  width: auto;
  height: auto;
}

.who-are-we-mobile-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}

.who-are-we-mobile-img-box {
  width: 100%;
  height: auto;
}

.who-are-we-mobile-img {
  width: 100%;
  height: auto;
}

.who-are-we-mobile-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.who-are-we-mobile-text-highlighted {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

/* ////////////////////////////////////////////////// OUR VALUES ////////////////////////////////////////////////// */

.our-values-mobile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  align-self: center;
  padding: 80px 10px;
  
  width: 100%;
  height: auto;
  background: var(--color-dark-black-60, #1E1E1E);
}

.our-values-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 100px;

  width: auto;
  height: auto;
}

.our-values-mobile-title {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 35px;
  line-height: 44px;

  text-align: center;
}

.our-values-mobile-cards {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 100px;
  
  width: auto;
  height: auto;
}

/* ////////////////////////////////////////////////// OFFICE ////////////////////////////////////////////////// */

.office-mobile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  align-self: center;
  padding: 80px 20px;
  background: #465DFF;

  width: 100%;
  height: auto;
}

.office-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 50px;

  width: auto;
  height: auto;
}

.office-mobile-section-title {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  
  text-align: center;
}

.office-mobile-title {
  width: auto;
  height: auto;

  font-weight: 600;
  font-size: 35px;
  line-height: 44px;

  text-align: center;
}

.office-mobile-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

/* ////////////////////////////////////////////////// OUR TEAM ////////////////////////////////////////////////// */

.our-team-mobile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 50px 10px;
  width: 100%;
  max-width: 360px;
  gap: 50px;
}

.our-team-mobile-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  text-align: start;
  align-self: flex-start;
}

.our-team-mobile-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
  align-self: flex-start;
}

.our-team-mobile-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
}