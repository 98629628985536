.photo-card-desktop-container {
  width: 204px;
  height: 460px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.photo-card-desktop-img-box {
  width: 204px;
  height: 351px;
}

.photo-card-desktop-img {
  width: 204px;
  height: 351px;
}

.photo-card-desktop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 15px;

  width: 309px;
  height: 107px;
}

.photo-card-desktop-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.photo-card-desktop-text-highlighted {
  color: #FEC40B;
}