.aviso-privacidad-mobile-container {
  width: 100%;
  height: auto;

  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column; 
}

/* ////////////////////////////////////////////////// SECTION ////////////////////////////////////////////////// */

.aviso-privacidad-mobile-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 360px;
  align-self: center;
  padding: 80px 20px;
  gap: 50px;
}

.aviso-privacidad-mobile-image {
  width: 100%;
  height: auto;
}

.aviso-privacidad-mobile-title {
  margin-top: 5vh;
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 50px;
  line-height: 76px;

  text-align: center;
}

.aviso-privacidad-mobile-subtitle {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  align-self: start;
  text-align: start;
}

.aviso-privacidad-mobile-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  align-self: start;
  text-align: start;
}