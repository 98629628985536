/* ////////////////////////////////////////////////// NAV ////////////////////////////////////////////////// */

.mobile-nav-container {
  display: flex;
  flex-direction: column;
  max-width: 744px;
  align-self: center;

  position: fixed;
  width: 100vw;
  min-height: auto;
  background-color: #000000;
  z-index: 999;
}

.mobile-nav-header {
  width: 100%;
  height: auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-home-image {
  width: 60%;
  height: auto;
  display: flex;
}


.mobile-nav-image {
  margin-left: 10px;
}

.mobile-nav-dim-screen {
  display: block;
  position: fixed;
  width: 744px;
  min-height: 120vh;
  background-color: rgba(30, 30, 30, 0.65);
  z-index: 9998;
}

.mobile-nav-dim-screen.hidden {
  display: none;
}

/* ////////////////////////////////////////////////// MENU ////////////////////////////////////////////////// */

.mobile-menu-container {
  position: fixed;
  padding: 10px;
  min-height: 100vh;
  min-width: 200px;
  background-color: #000;
  z-index: 9999;
  margin-top: -60px;
  bottom: 0px;
  margin-left: 0px;
  transition: margin-left 0.5s cubic-bezier(0.3, 1, 0.5, 1.0);
}

.mobile-menu-container.hidden {
  margin-left: -1100px;
}

.mobile-menu-close-icon-container {
  min-width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobile-menu-close-image {
  width: 30px;
  height: 30px;
  margin-top: 15px;
  margin-right: 10px;
}

.mobile-menu-logo-container {
  min-width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mobile-menu-logo-image {
  width: 140px;
}

.mobile-menu-btn-container {
  margin-bottom: 3vh;
  margin-top: 3vh;
}

.mobile-nav-menu-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 36px;
  gap: 8px;
  width: auto;
  height: 68px;
  background: #45D7A2;
  border: 1px solid #000000;
  border-radius: 40px;
  color: #000;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.mobile-menu-arrow-right {
  width: 24px;
  height: 24px;
}

hr.separator {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #a9a9a9;
  width: 80%;
  margin: 15px auto;
}

.mobile-menu-items-container {
  display: flex;
  flex-direction: column;
}

.mobile-menu-item-container {
  display: flex;
  align-items: center;
  margin: 5px 5px;
  border-bottom: 0.5px solid #FFF;
  padding: 15px;
}

.mobile-nav-menu-item {
  font-size: 0.8em;
  margin-left: 10px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.mobile-menu-linkedin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  margin-top: 3vh;
}

.mobile-menu-linkedin {
  width: 35px;
  height: 35px;
}