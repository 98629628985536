.tec-mobile-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    overflow: auto;
    min-height: 100vh;
}

/* ////////////////////////////////////////////////// SECTION MAGIC ////////////////////////////////////////////////// */

.tec-mobile-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 25px 10px;
    gap: 50px;
    max-width: 360px;
    width: 100%;
    margin-top: 11vh;
}

.tec-mobile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
    height: auto;
}

.tec-mobile-title {
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
    text-align: start;
}

.tec-mobile-text {
    font-size: 21px;
    font-weight: 600;
    line-height: 28px;
    text-align: start;
}

.tec-mobile-img {
    width: 328px;
    height: auto;
}

.tec-mobile-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 36px;
    gap: 16px;
    width: 100%;
    height: 68px;
    background: #FEC40B;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 40px;

    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
}

.tec-mobile-btn-black {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 36px;
    gap: 16px;
    width: 100%;
    height: 68px;
    background: #000000;
    border: 1px solid #FEC40B;
    color: #FFFFFF;
    border-radius: 40px;

    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
}

/* ////////////////////////////////////////////////// SECTION CARDS ////////////////////////////////////////////////// */

.tec-mobile-cards-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 80px 10px;
    width: 100%;
    max-width: 360px;
    gap: 50px;
    background: var(--color-dark-black-60, #1E1E1E);
}

/* ////////////////////////////////////////////////// SECTION CARDS ////////////////////////////////////////////////// */

.tec-mobile-rendimiento-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 50px 20px;
    max-width: 360px;
    gap: 100px;
    align-self: center;
    width: 100%;
    height: auto;
}

.tec-mobile-rendimiento-title {
    width: auto;
    height: auto;

    font-weight: 700;
    font-size: 35px;
    line-height: 44px;

    text-align: center;
    color: #FFFFFF;
}

.tec-mobile-rendimiento-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 80px;

    width: auto;
    height: auto;
}

.tec-mobile-rendimiento-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 32px;
}

.tec-mobile-rendimiento-card-title {
    width: auto;
    height: auto;

    font-weight: 700;
    font-size: 35px;
    line-height: 44px;

    text-align: center;
}

.tec-mobile-rendimiento-card-text {
    width: auto;
    height: auto;

    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

    text-align: center;
}

.tec-mobile-rendimiento-card-divider {
    width: 88px;
    height: 0px;

    border: 2px solid #FEC40B;
    border-radius: 6px;
}

/* ////////////////////////////////////////////////// SECTION RECONOCIMIENTO ////////////////////////////////////////////////// */

.tec-mobile-reconocimiento-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 360px;
    padding: 50px 10px;
    gap: 100px;
    width: 100%;
}

/* ////////////////////////////////////////////////// SECTION PASO PASO ////////////////////////////////////////////////// */

.tec-mobile-paso-paso-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 50px 10px;
    max-width: 360px;
    width: 100%;
    gap: 100px;
}

.tec-mobile-paso-paso-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.tec-mobile-paso-paso-section-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.tec-mobile-paso-paso-title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}

.tec-mobile-paso-paso-title {
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
}

.tec-mobile-paso-paso-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.tec-mobile-paso-paso-cards-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 75px;
}

/* ////////////////////////////////////////////////// SECTION HAZ MAS ////////////////////////////////////////////////// */

.tec-mobile-haz-mas-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 80px 20px;
    gap: 48px;
    width: 100%;
    height: auto;
    max-width: 360px;
    align-self: center;
    background: #1E1E1E;
}

.tec-mobile-haz-mas-title {
    width: 100%;
    height: auto;

    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
}

.tec-mobile-haz-mas-text {
    width: auto;
    height: auto;

    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

/* ////////////////////////////////////////////////// SECTION SAME COMPONENTS ////////////////////////////////////////////////// */

.same-mobile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 80px 10px;
    max-width: 360px;
    align-self: center;
    gap: 80px;
    width: 100%;
    height: auto;
}

.same-mobile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    gap: 32px;

    width: auto;
    height: auto;
}

.same-mobile-section-title {
    width: auto;
    height: auto;
    align-self: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.same-mobile-title {
    width: auto;
    height: auto;
    align-self: start;
    font-weight: 700;
    font-size: 35px;
    line-height: 44px;
}

.same-mobile-text {
    width: auto;
    height: auto;
    align-self: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.same-mobile-img {
    width: 327px;
    height: 333px;
}