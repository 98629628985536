.dynamic-image-container {
  padding: 50px;
}

.dynamic-image {
  width: 100%;
  height: auto;
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}