/* ////////////////////////////////////////////////// CTA ////////////////////////////////////////////////// */

.cta-mobile-section {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 68px;
  max-width: 360px;
  width: 100%;
  gap: 25px;
  background: #465DFF;
}

.cta-mobile-header-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.cta-mobile-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  min-height: 44px;
  color: #FFFFFF;
  text-align: start;
}

.cta-mobile-subtitle {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: #FFFFFF;
}

.cta-mobile-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: #FFFFFF;
}

.cta-mobile-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 36px;
  gap: 8px;
  width: 100%;
  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #000000;
}