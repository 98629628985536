* {
  color: #FFFFFF;
}

.home-tablet-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  overflow: auto;
  min-height: 100vh;
}

/* ////////////////////////////////////////////////// TEDIOSO ////////////////////////////////////////////////// */

.tedioso-tablet-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  max-width: 744px;
  align-self: center;
  padding: 80px 32px;
  gap: 2%;
  margin-top: 9vh;
}

.tedioso-tablet-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  max-width: 327px;
  width: 100%;
}

.tedioso-tablet-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 100%;
}

.tedioso-tablet-title-box {
  width: auto;
  height: 76px;
}

.tedioso-tablet-title {
  height: 44px;
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
}

.tedioso-tablet-subtitle {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  text-align: start;
}

.tedioso-tablet-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

.tedioso-tablet-btn-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;

  gap: 24px;
}

.tedioso-tablet-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px 25px;
  gap: 5px;

  width: 230px;
  height: auto;

  font-size: 16px;
  font-weight: 700;
  line-height: 28px;

  background: #FEC40B;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 28px;
  cursor: pointer;
  transition: background 0.25s ease-in;
}

.tedioso-tablet-btn:hover {
  background: #EC632B;
  color: #FFF;
  border: 1px solid #EC632B;
  transition: background 0.25s ease-in;
}

.tedioso-tablet-img-box {
  display: flex;
  justify-content: center;
  align-content: center;

  width: 48%;
  height: auto;
}

.tedioso-tablet-img {
  max-height: 445px;
  width: 100%;
  height: auto;
}

/* ////////////////////////////////////////////////// CONTACT ////////////////////////////////////////////////// */

.porcentajes-tablet-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 32px;

  gap: 100px;
  max-width: 744px;
  align-self: center;

  width: auto;
  height: auto;
  background: #1E1E1E;
}

.porcentajes-tablet-title {
  width: auto;
  height: auto;

  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.porcentajes-tablet-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: auto;
}

.porcentajes-tablet-subtitle {
  width: auto;
  height: 76px;
  left: 7px;
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}

.porcentajes-tablet-text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 56px;
  top: 76px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.porcentajes-tablet-divider {
  width: 0px;
  height: 88px;
  border: 2px solid #FEC40B;
  border-radius: 6px;
}

/* ////////////////////////////////////////////////// SOLUCION ////////////////////////////////////////////////// */

.solucion-tablet-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 744px;
  align-self: center;
  padding: 100px 110px;
  gap: 50px;

  width: auto;
  height: auto;
}

.solucion-tablet-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  width: auto;
  height: auto;
}

.solucion-tablet-section-title {
  width: auto;
  height: auto;

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.solucion-tablet-title {
  width: auto;
  height: auto;

  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
}

.solucion-tablet-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 80px;
  width: auto;
  height: auto;
}

.solucion-tablet-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

/* ////////////////////////////////////////////////// APP MOVIL ////////////////////////////////////////////////// */

.app-tablet-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  max-width: 744px;
  align-self: center;
  padding: 75px;

  width: 100%;
  height: auto;
  background: #1E1E1E;
}

.app-tablet-section-02 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-content: center;
  max-width: 744px;
  align-self: center;
  padding: 75px;
  background: #1E1E1E;
  
  width: 100%;
  height: auto;
}

/* ////////////////////////////////////////////////// SECTION PLATAFORMA WEB ////////////////////////////////////////////////// */

.home-fun-tablet-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 744px;
  align-self: center;
  padding: 100px 25px;
  gap: 100px;

  width: auto;
  height: auto;
}

.home-fun-tablet-text-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 125px;
  gap: 32px;
  width: 90%;
  height: auto;
}

.home-fun-tablet-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  width: auto;
  height: auto;
}

.home-fun-tablet-section-title {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.home-fun-tablet-title-box {
  width: auto;
  height: 76px;
}

.home-fun-tablet-title {
  font-weight: 700;
  font-size: 50px;
  line-height: 76px;
  text-align: center;
}

.home-fun-tablet-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 32px;
  width: 443px;
  height: auto;
}

.home-fun-tablet-subtitle {
  width: auto;
  height: auto;
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.home-fun-tablet-columns-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 32px;
  width: auto;
  height: auto;
}

.home-fun-tablet-text {
  width: auto;
  height: auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #FFFFFF;
}

.home-fun-tablet-text-green {
  width: auto;
  height: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #45D7A2;
}

.home-fun-tablet-cards-row {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 30px;

  width: 100%;
  height: auto;
}