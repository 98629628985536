.casos-uso-mobile-container {
    width: 100%;
    overflow: auto;
    min-height: 100vh;

    display: flex;
    align-items: center;
    flex-direction: column;
}

/* ////////////////////////////////////////////////// SECTION INICIO ////////////////////////////////////////////////// */

.casos-uso-mobile-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 20px;
    max-width: 360px;
	align-self: center;
    gap: 25px;
    width: 100%;
    height: auto;
    margin-top: 9vh;
}

.casos-uso-mobile-title {
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
}

.casos-uso-mobile-img {
    width: 327px;
    height: 430px;
}

.casos-uso-mobile-text {
    width: auto;
    height: auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.casos-uso-mobile-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 36px;
    gap: 8px;
    width: 100%;
    height: 68px;
    background: #FEC40B;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
}

/* ////////////////////////////////////////////////// SECTION CARDS ////////////////////////////////////////////////// */

.casos-uso-mobile-cards-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-self: center;
    padding: 80px 10px;
    max-width: 360px;
    width: 100%;
    gap: 30px;
}