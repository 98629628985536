.flip-card-desktop {
  background-color: transparent;
  width: 388px;
  height: 551px;
  perspective: 1000px;
}

.flip-card-desktop-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* ////////////////////////////////////////////////// FRONT ////////////////////////////////////////////////// */

.flip-card-desktop-container-front {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 55px 32px;
  gap: 55px;
  width: 100%;
  height: 100%;
  background: #1E1E1E;
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-desktop-img {
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
}

.flip-card-desktop-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
}

.flip-card-desktop-button {
  padding: 20px 36px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
  color: #000000;
  cursor: pointer;
  transition: background 0.25s ease-in;
}

.flip-card-desktop-button:hover {
  background: #EC632B;
  color: #FFFFFF;
  border: 1px solid #EC632B;
  transition: background 0.25s ease-in;
}

/* ////////////////////////////////////////////////// BOTH ////////////////////////////////////////////////// */

/* .flip-card-desktop-container-front,
.flip-card-desktop-container-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
} */


/* .flip-card-desktop:hover .flip-card-desktop-inner {
  transform: rotateY(180deg);
} */

/* ////////////////////////////////////////////////// BACK ////////////////////////////////////////////////// */

.flip-card-desktop-container-back {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 32px;
  gap: 30px;
  width: 100%;
  height: 100%;
  background: #45D7A2;
  border: 1px solid #45D7A2;
  border-radius: 25px;
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-desktop-icon-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flip-card-desktop-icon {
  width: 72px;
  height: 72px;
}

.flip-card-desktop-close-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.flip-card-desktop-text-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flip-card-desktop-title-back {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  color: #000000;
}

.flip-card-desktop-text-back {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
}