.photo-card-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  max-height: 460px;
  height: 100%;
}

.photo-card-mobile-img {
  width: 204px;
  height: 351px;
}

.photo-card-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.photo-card-mobile-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.photo-card-mobile-text-highlighted {
  color: #FEC40B;
}