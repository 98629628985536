* {
  color: #FFFFFF;
}

.home-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  overflow: auto;
  min-height: 100vh;
}

/* ////////////////////////////////////////////////// HEADER ////////////////////////////////////////////////// */

.header {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;

  padding: 25px 124px;

  width: 100%;
  max-width: 1440px;
  align-self: center;
  height: 118px;
  z-index: 900;
  background: #000000;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  width: 177.99px;
  height: 34.61px;
}

.header-logo-link {
  width: auto;
  height: auto;
}

.header-nav-list {
  display: flex;
  align-items: center;
  gap: 24px;

  width: auto;
  height: 68px;
}

.header-nav-views {
  display: flex;
  align-items: center;
  width: auto;
  height: 28px;
  gap: 28px
}

.header-link {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.header-btn-box {
  display: flex;
  align-items: center;

  width: auto;
  height: auto;
}

.header-btn {
  width: auto;
  height: auto;

  box-sizing: border-box;

  padding: 20px 36px;
  cursor: pointer;
  background: #45D7A2;
  color: #000000;
  border-radius: 40px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  text-align: center;
  transition: background 0.25s ease-in;
}

.header-btn:hover {
  color: #FFF;
  background: #465DFF;
  transition: background 0.25s ease-in;
}

.header-lang-box {
  flex: none;
  flex-grow: 0;
  order: 2;
}

.header-link-active {
  font-weight: 700;
}

/* ////////////////////////////////////////////////// TEDIOSO ////////////////////////////////////////////////// */

.tedioso-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  max-width: 1440px;
  align-self: center;

  padding: 80px 124px;
  gap: 2%;
  margin-top: 10vh;
}

.tedioso-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  padding: 0px;
  width: 48%;
  height: auto;
}

.tedioso-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 25px;

  width: 100%;
  height: auto;
}

.tedioso-title-box {
  width: auto;
  height: 76px;
}

.tedioso-title {
  height: 76px;
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
}

.tedioso-subtitle {
  width: auto;
  height: auto;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
}

.tedioso-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.tedioso-btn-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;

  gap: 24px;
}

.tedioso-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 36px;
  gap: 8px;

  width: auto;
  height: 68px;

  background: #FEC40B;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 40px;
  cursor: pointer;
  transition: background 0.25s ease-in;
}

.tedioso-btn:hover {
  background: #EC632B;
  color: #FFF;
  border: 1px solid #EC632B;
  transition: background 0.25s ease-in;
}

.tedioso-img-box {
  display: flex;
  justify-content: center;
  align-content: center;

  width: 48%;
  height: auto;
}

.tedioso-img {
  max-height: 765px;
  width: 100%;
  height: auto;
}

/* ////////////////////////////////////////////////// PORCENTAJES ////////////////////////////////////////////////// */

.porcentajes-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  padding: 80px 124px;
  max-width: 1440px;
  align-self: center;
  gap: 100px;
	width: 100%;
  background: #1E1E1E;
}

.porcentajes-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
}

.porcentajes-row {
  display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  gap: 80px;
	width: 100%;
}

.porcentajes-stat {
	display: flex;
	flex-direction: column;
}

.porcentajes-subtitle {
	font-size: 50px;
	font-weight: 700;
	line-height: 76px;
	text-align: center;
}

.porcentajes-text {
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
}

.porcentajes-divider {
	width: 2px;
	height: 88px;
	border: 2px solid #FEC40B;
	border-radius: 6px;
}

/* ////////////////////////////////////////////////// SOLUCION ////////////////////////////////////////////////// */

.solucion-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 100px 110px;
  max-width: 1440px;
  width: 100%;
  gap: 50px;
}

.solucion-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.solucion-section-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.solucion-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
}

.solucion-text-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;
  width: 100%;
}

.solucion-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 518px;
  width: 100%;
}

.solucion-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

/* ////////////////////////////////////////////////// APP MOVIL ////////////////////////////////////////////////// */

.app-section {
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  padding: 124px 0;
  max-width: 1440px;
  width: 100%;
}

.app-section-02 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  padding: 124px 0;
  max-width: 1440px;
  width: 100%;
  height: 1001px;
  background: #1E1E1E;
}

/* ////////////////////////////////////////////////// FUNCIONAIDADES ////////////////////////////////////////////////// */

.home-fun-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 1440px;
  padding: 100px 150px;
  gap: 100px;
}

.home-fun-text-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 32px;
  width: 90%;
  height: auto;
}

.home-fun-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 492px;
  height: 136px;
}

.home-fun-section-title {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.home-fun-title-box {
  width: auto;
  height: 76px;
}

.home-fun-title {
  font-weight: 700;
  font-size: 50px;
  line-height: 76px;
  text-align: center;
}

.home-fun-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 32px;
  width: 100%;
  height: auto;
}

.home-fun-subtitle {
  width: auto;
  height: 36px;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
}

.home-fun-columns-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 32px;
  width: auto;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.home-fun-column-text-01 {
  width: 50%;
  height: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.home-fun-column-text-green {
  width: 443px;
  height: 112px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #45D7A2;
}

.home-fun-column-text-02 {
  width: 50%;
  height: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.home-fun-cards-row {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 30px;

  width: 100%;
  height: auto;
}