/* ////////////////////////////////////////////////// COMMON ////////////////////////////////////////////////// */

.section-dynamic-scroll {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 150px 110px;
  gap: 20px;
  width: 100%;
  background: #373739;
}

.dynamic-scroll-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

/* ////////////////////////////////////////////////// LEFT ////////////////////////////////////////////////// */

.dynamic-scroll-box-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* align-self: flex-start; */
  /* gap: 10px; */
  gap: 56px;
  width: 493px;
  position: fixed;
  top: 25px;
  /* margin-left: 105px; */
}

.dynamic-scroll-content-box-left {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 10px;
  width: 493px;
}

.dynamic-scroll-box-left-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
  width: 30%;
  margin-left: 8%;
  position: absolute;
  top: 510%
}

.dynamic-scroll-box-left-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
  width: 30%;
  margin-left: 8%;
  position: absolute;
  top: 350%
}

.dynamic-scroll-left-title {
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
}

.dynamic-scroll-left-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  width: 418px;
}

.image-wrapper {
  position: absolute;
  height: auto;
}

.dynamic-scroll-image {
  width: 90%;
  max-width: 493px;
  height: auto;
}

/* ////////////////////////////////////////////////// RIGHT ////////////////////////////////////////////////// */

.dynamic-scroll-box-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 573px;
  /* width: 40%; */
  gap: 80px
}

.dynamic-scroll-divider {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 1px;
  /* height: 330px; */
  border-left: 3.5px solid #EC632B;
  border-right: 3.5px solid #EC632B;
  border-radius: 10px;
}

.dynamic-scroll-divider-blue {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 1px;
  /* height: 480px; */
  border-left: 3.5px solid #465dff;
  border-right: 3.5px solid #465dff;
  border-radius: 10px;
}

.dynamic-scroll-divider-green {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 1px;
  /* height: 480px; */
  border-left: 3.5px solid #45d7a2;
  border-right: 3.5px solid #45d7a2;
  border-radius: 10px;
}

.dynamic-scroll-circle {
  width: 40px;
  height: 40px;
}

.dynamic-scroll-circle-blue {
  align-self: center;
}

.dynamic-scroll-circle-green {
  align-self: end;
}

.dynamic-scroll-right-content {
  width: auto;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 10px;
}

.dynamic-scroll-icon {
  width: 40px;
  height: 40px;
}

.dynamic-scroll-right-title {
  font-weight: 700;
  font-size: 50px;
  line-height: 76px;
}