.contacto-mobile-container {
  display: flex;
  flex-direction: column;
  
  width: 100%;
  overflow: auto;
  min-height: 100vh;
}

/* ////////////////////////////////////////////////// OUR MISSION ////////////////////////////////////////////////// */

.contacto-mobile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 80px 20px;
  margin-top: 10vh;
  max-width: 360px;
  align-self: center;
  width: 100%;
  height: auto;
}

.contacto-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  gap: 50px;

  width: 100%;
  height: auto;
}

.contacto-mobile-title {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
}

.contacto-mobile-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.contacto-mobile-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contacto-mobile-label {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.contacto-mobile-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  width: auto;
  height: auto;
  background: #1E1E1E;
  border: 1px solid #D0D0D0;
  border-radius: 50px;
  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

.contacto-mobile-input[type=text]:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-mobile-input-focus {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  width: auto;
  height: auto;
  background: #1E1E1E;
  border-radius: 50px;
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-mobile-input-success {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  width: 100%;
  height: auto;
  background: #1E1E1E;
  border: 1px solid #45D7A2;
  border-radius: 50px;
  outline: none;
}

.contacto-mobile-input-success[type=text]:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-mobile-input-error {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  width: 100%;
  height: auto;
  background: #1E1E1E;
  border: 1px solid #FF2159;
  border-radius: 50px;
  outline: none;
  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

.contacto-mobile-input-error[type=text]:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-mobile-textarea {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 30px 100px;

  width: auto;
  height: auto;

  background: #1E1E1E;
  border: 1px solid #D0D0D0;
  border-radius: 20px;
}

.contacto-mobile-textarea:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-mobile-textarea-success {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;

  background: #1E1E1E;
  border: 1px solid #45D7A2;
  border-radius: 20px;
  outline: none;
}

.contacto-mobile-textarea-success:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-mobile-textarea-error {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;

  background: #1E1E1E;
  border: 1px solid #FF2159;
  border-radius: 20px;
  outline: none;
}

.contacto-mobile-textarea-error:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contacto-mobile-btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 24px;

  width: 100%;
  height: 68px;
}

.contacto-mobile-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  padding: 20px 36px;
  gap: 8px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  width: 100%;
  height: 68px;
  color: #000000;
  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
}

.contacto-mobile-btn:disabled {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;

  padding: 20px 36px;
  gap: 8px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  width: 100%;
  height: 68px;
  color: var(--color-dark-black-20, #4D4D4D); 

  border-radius: 40px;
  border: 1px solid var(--color-dark-black-40, #3C3C3C);
  background: var(--color-dark-black-60, #1E1E1E);
}

/* ////////////////////////////////////////////////// OVERLAY ////////////////////////////////////////////////// */

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* cursor: pointer; */
  z-index: 999;
}