.seguridad-tablet-container {
  width: 100%;
  height: auto;

  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}

/* ////////////////////////////////////////////////// SECTION ////////////////////////////////////////////////// */

.seguridad-tablet-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 744px;
  align-self: center;
  padding: 100px 32px;
  gap: 50px;
}

.seguridad-tablet-image {
  width: 100%;
  height: auto;
}

.seguridad-tablet-title {
  margin-top: 5vh;
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 50px;
  line-height: 76px;

  text-align: center;
}

.seguridad-tablet-subtitle {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  align-self: start;
  text-align: start;
}

.seguridad-tablet-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  align-self: start;
  text-align: start;
}

.seguridad-tablet-self-start {
  align-self: start;
}