.func-card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 30px;
  padding: 20px 25px;
  width: 100%;
  max-height: 90px;
  transition: background 0.5s, border 0.5s, color 0.5s, max-height 0.5s;
}

.func-card-icon {
  width: 40px;
  height: 40px;
  align-self: center;
}

.func-card-arrow-img {
  width: 24px;
  height: 24px;
  align-self: center;
}

/* ////////////////////////////////////////////////// ACIVE ////////////////////////////////////////////////// */

.func-card-container.func-card-active {
  max-height: 290px;
}

.func-card-active {
  background: #465DFF;
  border: 1px solid #465DFF;
  gap: 15px;
}

.func-card-frame-active {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  width: 100%;
}

.func-card-title-active {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: #FFFFFF;
  text-align: start;
}

.func-card-text-active {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #FFFFFF;
  text-align: start;
}

/* ////////////////////////////////////////////////// DEACIVE ////////////////////////////////////////////////// */

.func-card-deactive {
  background: #1E1E1E;
  border: 1px solid #FFFFFF;
  justify-content: space-between;
  flex-direction: row;
}

.func-card-frame-deactive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
}

.func-card-title-deactive {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  text-align: start;
  align-self: center;
}

/* ////////////////////////////////////////////////// MEDIA ////////////////////////////////////////////////// */

.rotateOn {
  -webkit-animation: rotateOn 0.5s;
  animation: rotateOn 0.5s;
  transform: rotate(180deg);
}

@-webkit-keyframes rotateOn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateOn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.rotateOff {
  -webkit-animation: rotateOff 0.5s;
  animation: rotateOff 0.5s;
  transform: rotate(0deg);
}

@-webkit-keyframes rotateOff {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotateOff {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.text-fade {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}