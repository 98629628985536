/* ////////////////////////////////////////////////// CTA ////////////////////////////////////////////////// */

.cta-tablet-section {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding: 40px 10px 40px 50px;
  max-width: 744px;
  width: 100%;
  gap: 25px;
  background: #465DFF;
}

.cta-tablet-header-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.cta-tablet-title {
  min-height: 76px;
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  color: #FFFFFF;
  text-align: start;
}

.cta-tablet-subtitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  color: #FFFFFF;
}

.cta-tablet-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #FFFFFF;
}

.cta-tablet-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 36px;
  gap: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #000000;
}