.dynamic-image-container {
  /* padding: 50px; */
}

.dynamic-image {
  width: 100%;
  height: auto;
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
  /* max-height: 450px; */
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}