.flip-card-mobile {
  background-color: transparent;
  width: 329px;
  height: 450px;
  perspective: 1000px;
}

.flip-card-mobile-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* ////////////////////////////////////////////////// FRONT ////////////////////////////////////////////////// */

.flip-card-mobile-container-front {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 35px 27px;
  gap: 40px;
  width: 100%;
  height: 100%;
  background: #1E1E1E;
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-mobile-img {
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
}

.flip-card-mobile-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.flip-card-mobile-button {
  padding: 20px 36px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
  color: #000000;
  cursor: pointer;
  transition: background 0.25s ease-in;
}

.flip-card-mobile-button:hover {
  background: #EC632B;
  color: #FFFFFF;
  border: 1px solid #EC632B;
  transition: background 0.25s ease-in;
}

/* ////////////////////////////////////////////////// BACK ////////////////////////////////////////////////// */

.flip-card-mobile-container-back {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  padding: 42px 27px;
  gap: 25px;
  width: 100%;
  height: 100%;
  background: #45D7A2;
  border: 1px solid #45D7A2;
  border-radius: 25px;
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-mobile-icon-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flip-card-mobile-icon {
  width: 61px;
  height: 61px;
}

.flip-card-mobile-close-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.flip-card-mobile-text-row {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.flip-card-mobile-title-back {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: #000000;
}

.flip-card-mobile-text-back {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
}