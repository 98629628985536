.dynamiccard-box {
  width: 100%;
  height: auto;
}

.dynamiccard-box-active {
  height: auto;
}

.dynamiccard-box-deactive {
  height: 107px;
}

.dynamiccard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 5%;

  width: auto;
  height: auto;
  max-height: 140px;
  border-radius: 30px;
  transition: background 0.5s ease-in-out, color 0.5s ease-in-out, border 0.5s ease-in-out, max-height 0.5s;
}

.dynamiccard-active {
  height: auto;
  /* border: 1px solid #45D7A2;
  background-color: #45D7A2; */
}

.dynamiccard-deactive {
  height: auto;
  border: 1px solid #FFFFFF;
  background-color: #1E1E1E;
}

.dynamiccard-frame {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5%;
  width: 80%;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.dynamiccard-img-box {
  /* width: 55px;
  height: 55px; */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.dynamiccard-img {
  width: 55px;
  height: 55px;
}

.dynamiccard-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.dynamiccard-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 90%;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.dynamiccard-text {
  width: auto;
  height: auto;
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
}

.dynamiccard-arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  /* margin-right: 25px; */
  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

.arrow-img {
  width: 18px;
  height: auto;
}

.active {
  color: #000000;
  height: auto;
}

.deactive {
  color: #FFFFFF;
}

.fade-in {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.rotateOn {
  -webkit-animation: rotateOn 0.5s;
  animation: rotateOn 0.5s;
  transform: rotate(180deg);
  -webkit-filter: invert(1);
  filter: invert(1);
}

@-webkit-keyframes rotateOn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateOn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.rotateOff {
  -webkit-animation: rotateOff 0.5s;
  animation: rotateOff 0.5s;
  transform: rotate(0deg);
  -webkit-filter: invert(0);
  filter: invert(0);
}

@-webkit-keyframes rotateOff {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotateOff {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}