.video-backdrop {
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  overflow: hidden;
  z-index: 999;
  padding: 80px 20px;
  background-color: rgba(30, 30, 30, 0.65);
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.video-btn-box {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.video-close-icon {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 25px;
  height: 25px;
  align-self: flex-end;
  margin-right: 20%;
}

.video-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}