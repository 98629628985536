.quienes-somos-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-height: 100vh;
}

/* ////////////////////////////////////////////////// HEADER ////////////////////////////////////////////////// */

.header {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;

  padding: 25px 124px;
  max-width: 1440px;
  align-self: center;

  width: 100%;
  height: 118px;
  z-index: 900;
  background: #000000;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  width: 177.99px;
  height: 34.61px;
}

.header-logo-link {
  width: auto;
  height: auto;
}

.header-nav-list {
  display: flex;
  align-items: center;
  gap: 24px;

  width: auto;
  height: 68px;
}

.header-nav-views {
  display: flex;
  align-items: center;
  width: auto;
  height: 28px;
  gap: 28px
}

.header-link {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.header-btn-box {
  display: flex;
  align-items: center;

  width: auto;
  height: auto;
}

.header-btn {
  width: auto;
  height: auto;

  box-sizing: border-box;

  padding: 20px 36px;
  cursor: pointer;
  background: #45D7A2;
  color: #000000;
  border-radius: 40px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  text-align: center;
}

.header-lang-box {
  flex: none;
  flex-grow: 0;
  order: 2;
}

.header-link-active {
  font-weight: 700;
}

/* ////////////////////////////////////////////////// OUR MISSION ////////////////////////////////////////////////// */

.our-mission-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 1440px;
  margin-top: 10vh;
}

.our-mission-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 356px 110px;
  gap: 25px;
  width: 100%;
}

.our-mission-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
}

.our-mission-text {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

/* ////////////////////////////////////////////////// WHO ARE WE ////////////////////////////////////////////////// */

.who-are-we-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  align-self: center;
  padding: 100px 110px;
  gap: 50px;
  width: 100%;
}

.who-are-we-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
}

.who-are-we-img {
  width: 1148px;
  height: 101px;
}

.who-are-we-text-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 48px;
  width: 100%;
}

.who-are-we-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

/* ////////////////////////////////////////////////// OUR VALUES ////////////////////////////////////////////////// */

.our-values-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 1440px;
  gap: 100px;
  padding: 150px 110px;
  background-color: #1E1E1E;
}

.our-values-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
}

.our-values-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 100px;
  width: 100%;
}

.our-values-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  width: 100%;
}

/* ////////////////////////////////////////////////// OFFICE ////////////////////////////////////////////////// */

.office-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 220px;
  gap: 10px;
  max-width: 1440px;
  align-self: center;
  height: 326px;
  background: #465DFF;
  width: 100%;
}

.office-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 50px;
  width: 492px;
  height: 166px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.office-section-title {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.office-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;
  width: 492px;
  height: 88px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.office-title {
  width: auto;
  height: 28px;
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.office-text {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  flex: none;
  order: 2;
  flex-grow: 0;
}

/* ////////////////////////////////////////////////// OUR TEAM ////////////////////////////////////////////////// */

.our-team-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-self: center;
  width: 100%;
  max-width: 1440px;
  gap: 136px;
  padding: 150px 110px;
}

.our-team-text-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  width: 100%;
}

.our-team-title {
  font-size: 35px;
  font-weight: 600;
  line-height: 44px;
  text-align: start;
}

.our-team-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

.our-team-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.our-team-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 182px;
  width: 100%;
}