.our-values-card-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 309px;
  width: 100%;
  gap: 25px;
}

.our-values-card-mobile-img {
  width: 80px;
  height: 80px;
}

.our-values-card-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.our-values-card-mobile-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.our-values-card-mobile-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}