.numbered-image-card-desktop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 388px;
}

.numbered-image-card-desktop-image {
  width: 388px;
  height: 130px;
}

.numbered-image-card-desktop-text-box {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 90%;
}

.numbered-image-card-desktop-title {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-self: center;
}

.numbered-image-card-desktop-number-text {
  font-size: 35px;
  font-weight: 600;
  line-height: 44px;
  color: #45D7A2;
  text-align: center;
}

.numbered-image-card-desktop-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  color: #FFF;
  text-align: center;
}

.numbered-image-card-desktop-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}