.app-img {
  width: 100%;
  height: auto;
  max-width: 571px;
}

.wide-image {
  display: flex;
  position: absolute;
  width: 686px;
  height: 747px;
  margin-left: 640px;
}