.line-image-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* padding: 80px 20px; */
    gap: 25px;

    width: 100%;
    height: auto;
}

.line-image-card-box-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 25px;
    width: 100%;
    height: auto;
}

.line-image-card-card-tablet-title {
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
}

.line-image-card-card-title {
    width: auto;
    height: auto;

    font-weight: 600;
    font-size: 21px;
    line-height: 28px;
}

.line-image-card-tablet-text {
    width: 100%;
    height: auto;

    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.line-image-card-text {
    width: auto;
    height: auto;

    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.line-image-card-img-box {
    width: 100%;
    height: auto;
}

.line-image-card-img {
    width: 100%;
    height: auto;
}

.line-image-card-box-right-tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    gap: 40px;
    margin-top: 78px;
    width: 100%;
    height: auto;
}

.line-image-card-box-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    gap: 40px;
    margin-top: 78px;

    width: auto;
    height: auto;
}

.line-image-card-divider {
    box-sizing: border-box;
    display: flex;
    align-content: start;
    justify-content: center;
    width: 1px;
    height: auto;
    border-left: 3.5px solid #EC632B;
    border-right: 3.5px solid #EC632B;
    border-radius: 10px;
}

.line-image-card-divider-blue {
    box-sizing: border-box;
    display: flex;
    align-content: start;
    justify-content: center;
    width: 1px;
    height: auto;
    border-left: 3.5px solid #465DFF;
    border-right: 3.5px solid #465DFF;
    border-radius: 10px;
}

.line-image-card-divider-green {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 1px;
    height: auto;
    border-left: 3.5px solid #45D7A2;
    border-right: 3.5px solid #45D7A2;
    border-radius: 10px;
}

.line-image-card-circle {
    width: 40px;
    height: 40px;
    align-self: start;
}

.line-image-card-circle-blue {
    width: 40px;
    height: 40px;
    align-self: center;
}

.line-image-card-circle-green {
    width: 40px;
    height: 40px;
    align-self: end;
}

.line-image-card-title {
    width: auto;
    height: auto;

    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
}

.line-image-card-content-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    gap: 40px;

    width: auto;
    height: auto;
}

.line-image-card-icon {
    width: 40px;
    height: 40px;
    margin-top: 5px;
}

.line-image-card-arrow {
    color: #FFFFFF;
}