/* ////////////////////////////////////////////////// ALERT ////////////////////////////////////////////////// */

.custom-alert {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.custom-mobile-alert-box {
  padding: 3% 5%;
  background-color: #FFFFFF;
  border-radius: 25px;
  gap: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.custom-alert-box {
  padding: 40px 60px;
  background-color: #FFFFFF;
  border-radius: 25px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.custom-alert-close-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.custom-alert-close-img {
  cursor: pointer;
  width: 25px;
  height: 25px;
  -webkit-filter: invert(1);
  filter: invert(1);
}

.custom-alert-close-image {
  width: 50px;
  height: 50px;
}

.custom-alert-close-image-invert {
  width: 50px;
  height: 50px;
  -webkit-filter: invert(1);
  filter: invert(1);
}

.custom-alert-content-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.custom-alert-img {
  width: 50px;
  height: 50px;
}

.custom-alert-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.custom-alert-text-bold {
  font-weight: 999;
  font-size: 24px;
  line-height: 28px;
  max-width: 300px;
  color: #000000;
  text-align: center;
}

.custom-alert-text {
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  max-width: 300px;
  color: #000000;
  text-align: center;
}