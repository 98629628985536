.dynamic-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-self: center;
  max-width: 1213px;
  width: 100%;
  gap: 72px;
}

.dynamic-card-container-02 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-content: center;
  align-self: first baseline;
  max-width: 1213px;
  width: 100%;
  gap: 72px;
}

.app-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  gap: 72px;

  width: auto;
  height: auto;
}

.app-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 56px;

  width: 47.5%;
  height: auto;
}

.app-titles-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 16px;
  width: auto;
  height: auto;
}

.app-titles-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 16px;
  width: auto;
  height: auto;
}

.app-section-title {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.app-title {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 50px;
  line-height: 76px;
}

.app-cards {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 24px;
  width: 100%;
  height: auto;
}