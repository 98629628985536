.image-card-titled-desktop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.image-card-titled-desktop-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 169px;
  height: 170px;
}

.card-titled-img {
  width: auto;
  height: auto;
}

.image-card-titled-desktop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.image-card-titled-desktop-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.image-card-titled-desktop-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}