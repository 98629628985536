/* ////////////////////////////////////////////////// CTA ////////////////////////////////////////////////// */

.cta-desktop-section {
  background: #000000;
}

.cta-desktop-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 110px;
  padding-right: 110px;
  padding-bottom: 40px;
}

.cta-desktop-content-box {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 15px;
  justify-content: flex-start;
}

.cta-desktop-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  color: #FFFFFF;
}

.cta-desktop-subtitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  color: #FFFFFF;
}

.cta-desktop-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #FFFFFF;
}

.cta-desktop-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 36px;
  gap: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
  color: #000000;
  transition: background 0.25s ease-in;
}

.cta-desktop-btn:hover {
  background: #EC632B;
  color: #FFF;
  border: 1px solid #EC632B;
  transition: background 0.25s ease-in;
}

.cta-desktop-img-box {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  max-width: 489px;
  width: 100%;
}

.cta-desktop-img {
  width: auto;
  height: auto;
}