.tecnologia-container {
	display: flex;
	flex-direction: column;

	width: 100%;
	overflow: auto;
	min-height: 100vh;
}

/* ////////////////////////////////////////////////// HEADER ////////////////////////////////////////////////// */

.header {
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: center;

	padding: 25px 124px;
	max-width: 1440px;
	align-self: center;
	width: 100%;
	height: 118px;
	z-index: 900;
	background: #000000;
}

.header-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.header-logo {
	width: 177.99px;
	height: 34.61px;
}

.header-logo-link {
	width: auto;
	height: auto;
}

.header-nav-list {
	display: flex;
	align-items: center;
	gap: 24px;

	width: auto;
	height: 68px;
}

.header-nav-views {
	display: flex;
	align-items: center;
	width: auto;
	height: 28px;
	gap: 28px
}

.header-link {
	width: auto;
	height: 28px;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	color: #FFFFFF;
}

.header-btn-box {
	display: flex;
	align-items: center;

	width: auto;
	height: auto;
}

.header-btn {
	width: auto;
	height: auto;

	box-sizing: border-box;

	padding: 20px 36px;
	cursor: pointer;
	background: #45D7A2;
	color: #000000;
	border-radius: 40px;

	font-weight: 700;
	font-size: 16px;
	line-height: 28px;

	text-align: center;
}

.header-lang-box {
	flex: none;
	flex-grow: 0;
	order: 2;
}

.header-link-active {
	font-weight: 700;
}

/* ////////////////////////////////////////////////// MAGIA ////////////////////////////////////////////////// */

.magia-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	width: 100%;
	max-width: 1440px;
	gap: 61px;
	padding: 100px 110px;
	margin-top: 11vh;
}

.magia-text-container {
	display: flex;
	flex-direction: column;
	gap: 50px;
}

.magia-text-box {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.magia-title-text {
	font-size: 50px;
	font-weight: 700;
	line-height: 76px;
}

.magia-text {
	font-size: 25px;
	font-weight: 700;
	line-height: 36px;
}

.magia-btns-row {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 50px;
	width: 100%;
}

.magia-btn-demo {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 36px;
	gap: 8px;
	width: auto;
	height: 68px;
	font-size: 16px;
	font-weight: 700;
	line-height: 28px;
	text-align: center;
	color: #000000;
	background: #FEC40B;
	border: 1px solid #000000;
	border-radius: 40px;
	cursor: pointer;
	transition: background 0.25s ease-in;
}

.magia-btn-demo:hover {
	background: #EC632B;
	color: #FFF;
	border: 1px solid #EC632B;
	transition: background 0.25s ease-in;
}

.magia-btn-video {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 36px;
	gap: 8px;
	width: auto;
	height: 68px;
	background: #1E1E1E;
	font-size: 16px;
	font-weight: 700;
	line-height: 28px;
	text-align: center;
	border: 1px solid #FEC40B;
	border-radius: 40px;
	cursor: pointer;
	transition: background 0.25s ease-in;
}

.magia-btn-video:hover {
	color: #000;
	background: #FEC40B;
	border: 1px solid #000;
	transition: background 0.25s ease-in;
}

.magia-image {
	width: 563px;
	height: 660px;
}

/* ////////////////////////////////////////////////// CARDS ////////////////////////////////////////////////// */

.cards-content {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	align-self: center;
	padding: 150px 110px;
	width: 100%;
	max-width: 1440px;
	gap: 40px;
	background: #1E1E1E;
}

/* ////////////////////////////////////////////////// RENDIMIENTO ////////////////////////////////////////////////// */

.rendimiento-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 1440px;
	align-self: center;
	padding: 150px 110px;
	width: 100%;
	gap: 100px;
}

.rendimiento-title {
	font-size: 50px;
	font-weight: 700;
	line-height: 76px;
	text-align: center;
}

.rendimiento-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 80px;
	width: 100%;
}

.rendimiento-row-card {
	display: flex;
	flex-direction: column;
}

.rendimiento-row-card-title {
	font-size: 50px;
	font-weight: 700;
	line-height: 76px;
	text-align: center;
}

.rendimiento-row-card-text {
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
}

.rendimiento-row-divider {
	width: 2px;
	height: 88px;
	border: 2px solid #FEC40B;
	border-radius: 6px;
}

/* ////////////////////////////////////////////////// RECONOCIMIENTO ////////////////////////////////////////////////// */

.reconocimiento-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
	padding: 150px 110px;
	width: 100%;
	max-width: 1440px;
	align-self: center;
	gap: 100px;
}

/* ////////////////////////////////////////////////// PASO PASO ////////////////////////////////////////////////// */

.paso-paso-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	max-width: 1440px;
	gap: 100px;
	padding: 150px 110px;
	width: 100%;
}

.paso-paso-text-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;
}

.paso-paso-section-title {
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
}

.paso-paso-title-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

.paso-paso-title {
	font-size: 50px;
	font-weight: 700;
	line-height: 76px;
	text-align: center;
}

.paso-paso-subtitle {
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
}

.paso-paso-cards-row {
	display: flex;
	flex-direction: row;
	gap: 30px;
}

/* ////////////////////////////////////////////////// HAZ MAS ////////////////////////////////////////////////// */

.haz-mas-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 1440px;
	width: 100%;
	align-self: center;
	gap: 5%;
	padding: 80px 124px;
	background-color: #1E1E1E;
}

.haz-mas-title {
	width: 47.5%;
	height: auto;

	font-weight: 600;
	font-size: 35px;
	line-height: 44px;

	flex: none;
	order: 0;
	flex-grow: 0;
}

.haz-mas-subtitle {
	width: 47.5%;
	height: auto;

	font-weight: 400;
	font-size: 18px;
	line-height: 28px;

	flex: none;
	order: 1;
	flex-grow: 0;
}

/* ////////////////////////////////////////////////// CONCILIACION ////////////////////////////////////////////////// */

.conciliacion-section {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	max-width: 1440px;
	width: 100%;
	align-self: center;
	padding: 80px 124px;
	gap: 5%;
}

.conciliacion-img-box {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	width: 47.5%;
	height: auto;

	flex: none;
	order: 0;
	flex-grow: 0;
}

.conciliacion-img {
	width: 100%;
	height: auto;
}

.conciliacion-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	gap: 24px;
	width: 47.5%;
	height: auto;

	flex: none;
	order: 1;
	flex-grow: 0;
}

.conciliacion-section-title {
	width: auto;
	height: auto;

	font-weight: 400;
	font-size: 18px;
	line-height: 28px;

	flex: none;
	order: 0;
	flex-grow: 0;
}

.conciliacion-title {
	width: auto;
	height: auto;

	font-weight: 700;
	font-size: 50px;
	line-height: 76px;

	flex: none;
	order: 1;
	flex-grow: 0;
}

.conciliacion-text {
	width: auto;
	height: auto;

	font-weight: 400;
	font-size: 18px;
	line-height: 28px;

	flex: none;
	order: 2;
	flex-grow: 0;
}

/* ////////////////////////////////////////////////// HISTORIAL ////////////////////////////////////////////////// */

.historial-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 1440px;
	align-self: center;
	gap: 24px;
	width: 47.5%;
	height: auto;

	flex: none;
	order: 0;
	flex-grow: 0;
}

.historial-img-box {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	width: 47.5%;
	height: auto;

	flex: none;
	order: 0;
	flex-grow: 0;
}