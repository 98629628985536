.numbered-img-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: auto;
  height: auto;
  gap: 24px;
}

.numbered-img-card-img-box {
  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.numbered-img-card-img {
  width: 100%;
  height: auto;
  /* min-width: 108px; */
}

.numbered-img-card-text-box {
  display: flex;
  flex-direction: column;
  align-items: left;

  gap: 32px;
  width: auto;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.numbered-img-card-title-box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: 16px;
  width: auto;
  height: auto;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.numbered-img-card-number-text {
  width: auto;
  height: auto;
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  color: #45D7A2;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.numbered-img-card-title {
  width: auto;
  height: auto;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.numbered-img-card-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: start;
  margin-bottom: 60px;

  flex: none;
  order: 1;
  flex-grow: 0;
}