* {
  color: #FFFFFF;
}

.home-mobile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-height: 100vh;
}

/* ////////////////////////////////////////////////// TEDIOSO ////////////////////////////////////////////////// */

.tedioso-mobile-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 10px;
  max-width: 360px;
  align-self: center;
  gap: 50px;
  width: 100%;
  margin-top: 11vh;
}

.tedioso-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 100%;
}

.tedioso-mobile-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  min-height: 44px;
  text-align: start;
}

.tedioso-mobile-subtitle {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  text-align: start;
}

.tedioso-mobile-img {
  width: 100%;
  height: auto;
}

.tedioso-mobile-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

.tedioso-mobile-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 36px;
  gap: 16px;
  width: 100%;
  background: #FEC40B;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}

/* ////////////////////////////////////////////////// CONTACT ////////////////////////////////////////////////// */

.porcentajes-mobile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 80px 10px;
  width: 100%;
  max-width: 360px;
  gap: 100px;
  background: #1E1E1E;
}

.porcentajes-mobile-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.porcentajes-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.porcentajes-mobile-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.porcentajes-mobile-subtitle {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}

.porcentajes-mobile-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.porcentajes-mobile-divider {
  width: 88px;
  border: 2px solid #FEC40B;
}

/* ////////////////////////////////////////////////// SOLUCION ////////////////////////////////////////////////// */

.solucion-mobile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  max-width: 360px;
  padding: 50px 10px;
  width: 100%;
  gap: 50px;
}

.solucion-mobile-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
}

.solucion-mobile-section-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.solucion-mobile-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}

.solucion-mobile-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

/* ////////////////////////////////////////////////// APP MOVIL ////////////////////////////////////////////////// */

.app-mobile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  max-width: 360px;
  align-self: center;
  width: 100%;
  height: auto;
}

.web-platform-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 124px 0px;
  gap: 10px;
  height: 937px;
  background: #1E1E1E;
}

/* ////////////////////////////////////////////////// SECTION PLATAFORMA WEB ////////////////////////////////////////////////// */

.home-fun-mobile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 360px;
  padding: 50px 10px;
  gap: 32px;
}

.home-fun-mobile-section-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.home-fun-mobile-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  min-height: 44px;
  text-align: center;
}

.home-fun-mobile-subtitle {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

.home-fun-mobile-text {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

.home-fun-mobile-text-green {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #45D7A2;
}

.home-fun-mobile-cards-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 100%;
}