.quienes-somos-tablet-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-height: 100vh;
}

/* ////////////////////////////////////////////////// OUR MISSION ////////////////////////////////////////////////// */

.our-mission-tablet-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 744px;
  align-self: center;
  padding: 80px 20px;
  margin-top: 10vh;

  width: 100%;
  height: auto;
}

.our-mission-tablet-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 25px;

  width: auto;
  height: auto;
}

.our-mission-tablet-title {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 35px;
  line-height: 44px;

  text-align: center;
}

.our-mission-tablet-text {
  width: auto;
  height: auto;

  font-weight: 600;
  font-size: 21px;
  line-height: 28px;

  text-align: center;
}

/* ////////////////////////////////////////////////// WHO ARE WE ////////////////////////////////////////////////// */

.who-are-we-tablet-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 100px 32px;
  max-width: 744px;
  width: 100%;
  gap: 50px;
}

.who-are-we-tablet-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}

.who-are-we-tablet-img {
  width: 100%;
  height: auto;
}

.who-are-we-tablet-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}

.who-are-we-tablet-text-highlighted {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

/* ////////////////////////////////////////////////// OUR VALUES ////////////////////////////////////////////////// */

.our-values-tablet-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 744px;
  align-self: center;
  padding: 80px 20px;
  
  width: 100%;
  height: auto;
  background: #1E1E1E;
}

.our-values-tablet-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 100px;

  width: 100%;
  height: auto;
}

.our-values-tablet-title {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 35px;
  line-height: 44px;

  text-align: center;
}

.our-values-tablet-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  width: 100%;
}

.our-values-tablet-cards-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  width: 100%;
}

/* ////////////////////////////////////////////////// OFFICE ////////////////////////////////////////////////// */

.office-tablet-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 744px;
  align-self: center;
  padding: 80px 20px;
  background: #465DFF;

  width: 100%;
  height: auto;
}

.office-tablet-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 50px;

  width: auto;
  height: auto;
}

.office-tablet-section-title {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  
  text-align: center;
}

.office-tablet-title {
  width: auto;
  height: auto;

  font-weight: 600;
  font-size: 35px;
  line-height: 44px;

  text-align: center;
}

.office-tablet-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  text-align: center;
}

/* ////////////////////////////////////////////////// OUR TEAM ////////////////////////////////////////////////// */

.our-team-tablet-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 744px;
  align-self: center;
  padding: 80px 20px;

  width: 100%;
  height: auto;
}

.our-team-tablet-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 100px;

  width: auto;
  height: auto;
}

.our-team-tablet-title {
  width: auto;
  height: auto;

  font-weight: 600;
  font-size: 35px;
  line-height: 44px;

  text-align: start;
}

.our-team-tablet-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  
  text-align: start;
}

.our-team-tablet-cards {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 91px;

  width: auto;
  height: auto;
}

.our-team-tablet-cards-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 182px;

  width: auto;
  height: auto;
}