.casos-uso-container {
	display: flex;
	flex-direction: column;

	width: 100%;
	overflow: auto;
	min-height: 100vh;
}

/* ////////////////////////////////////////////////// HEADER ////////////////////////////////////////////////// */

.header {
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: center;

	padding: 25px 124px;
	max-width: 1440px;
	align-self: center;
	width: 100%;
	height: 118px;
	z-index: 900;
	background: #000000;
}

.header-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.header-logo {
	width: 177.99px;
	height: 34.61px;
}

.header-logo-link {
	width: auto;
	height: auto;
}

.header-nav-list {
	display: flex;
	align-items: center;
	gap: 24px;

	width: auto;
	height: 68px;
}

.header-nav-views {
	display: flex;
	align-items: center;
	width: auto;
	height: 28px;
	gap: 28px
}

.header-link {
	width: auto;
	height: 28px;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	color: #FFFFFF;
}

.header-btn-box {
	display: flex;
	align-items: center;

	width: auto;
	height: auto;
}

.header-btn {
	width: auto;
	height: auto;

	box-sizing: border-box;

	padding: 20px 36px;
	cursor: pointer;
	background: #45D7A2;
	color: #000000;
	border-radius: 40px;

	font-weight: 700;
	font-size: 16px;
	line-height: 28px;

	text-align: center;
}

.header-lang-box {
	flex: none;
	flex-grow: 0;
	order: 2;
}

.header-link-active {
	font-weight: 700;
}

/* ////////////////////////////////////////////////// SECTION INICIO ////////////////////////////////////////////////// */

.casos-uso-section {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-self: center;
	width: 100%;
	max-width: 1440px;
	margin-top: 11vh;
	padding: 100px 110px;
	gap: 220px;
}

.casos-uso-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 50px;
	max-width: 448px;
	width: 100%;
}

.casos-uso-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
	width: 100%;
}

.casos-uso-title {
	font-size: 50px;
	font-weight: 700;
	line-height: 76px;
	color: #FFFFFF;
	text-align: start;
}

.casos-uso-paragraph {
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	color: #FFFFFF;
	text-align: start;
}

.casos-uso-btn {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 36px;
	gap: 8px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-size: 16px;
	font-weight: 700;
	line-height: 28px;
	background: #FEC40B;
	border: 1px solid #000000;
	border-radius: 40px;
	color: #000000;
	transition: background 0.25s ease-in;
}

.casos-uso-btn:hover {
	background: #EC632B;
	color: #FFF;
	border: 1px solid #EC632B;
	transition: background 0.25s ease-in;
}

.casos-uso-img-box {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: 550px;
}

/* ////////////////////////////////////////////////// SECTION CARDS ////////////////////////////////////////////////// */

.casos-uso-cards-section {
	display: flex;
	flex-direction: column;
	align-self: center;
	gap: 30px;
	max-width: 1440px;
	padding: 80px 124px;
}