.tec-tablet-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    overflow: auto;
    min-height: 100vh;
}

/* ////////////////////////////////////////////////// SECTION MAGIC ////////////////////////////////////////////////// */

.tec-tablet-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 100px 32px;
    max-width: 744px;
    align-self: center;
    gap: 16px;
    width: 100%;
    height: auto;
    margin-top: 10vh;
}

.tec-tablet-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    gap: 25px;
    width: 350px;
    height: auto;
}

.tec-tablet-title {
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
    text-align: start;
}

.tec-tablet-text {
    font-size: 21px;
    font-weight: 600;
    line-height: 28px;
    text-align: start;
}

.tec-tablet-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding: 20px 36px;
    gap: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 68px;
    background: #FEC40B;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 40px;

    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
}

.tec-tablet-btn-black {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 36px;
    gap: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 68px;
    background: #000000;
    border: 1px solid #FEC40B;
    color: #FFFFFF;
    border-radius: 40px;

    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
}

.tec-tablet-img {
    width: 300px;
    height: auto;
}

/* ////////////////////////////////////////////////// SECTION CARDS ////////////////////////////////////////////////// */

.tec-tablet-cards-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 160px 20px;
    max-width: 744px;
    align-self: center;
    width: 100%;
    height: auto;
    gap: 40px;
    background: #1E1E1E;
}

/* ////////////////////////////////////////////////// SECTION RENDIMIENTO ////////////////////////////////////////////////// */

.tec-tablet-rendimiento-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 150px 32px;
    max-width: 744px;
    align-self: center;
    width: 100%;
    height: auto;
}

.tec-tablet-rendimiento-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 100px;

    width: 100%;
    height: auto;
}

.tec-tablet-rendimiento-title {
    width: auto;
    height: auto;

    font-size: 35px;
    font-weight: 700;
    line-height: 44px;

    text-align: center;
    color: #FFFFFF;
}

.tec-tablet-rendimiento-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    width: auto;
    height: auto;
}

.tec-tablet-rendimiento-card-01 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 144px;
}

.tec-tablet-rendimiento-card-02 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 214px;
}

.tec-tablet-rendimiento-card-03 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 206px;
}

.tec-tablet-rendimiento-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.tec-tablet-rendimiento-card-title {
    width: auto;
    height: auto;

    font-size: 35px;
    font-weight: 700;
    line-height: 44px;

    text-align: center;
}

.tec-tablet-rendimiento-card-text {
    width: auto;
    height: auto;

    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

    text-align: center;
}

.tec-tablet-rendimiento-card-divider {
    width: 0px;
    height: 88px;

    border: 2px solid #FEC40B;
    border-radius: 6px;
}

/* ////////////////////////////////////////////////// SECTION RECONOCIMIENTO ////////////////////////////////////////////////// */

.tec-tablet-reconocimiento-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 744px;
    align-self: center;
    padding: 100px 32px;
    gap: 100px;
    width: 100%;
    background: var(--color-dark-black-80, #111);
}

/* ////////////////////////////////////////////////// SECTION PASO PASO ////////////////////////////////////////////////// */

.tec-tablet-paso-paso-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 150px 64px;
    max-width: 744px;
    gap: 100px;
    align-self: center;
    width: 100%;
    height: auto;
}

.tec-tablet-paso-paso-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 50px;

    width: auto;
    height: auto;
}

.tec-tablet-paso-paso-section-title {
    width: auto;
    height: auto;

    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    text-align: center;
}

.tec-tablet-paso-paso-content-02 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 16px;

    width: 100%;
    height: auto;
}

.tec-tablet-paso-paso-title {
    width: auto;
    height: auto;

    font-size: 35px;
    font-weight: 700;
    line-height: 44px;

    text-align: center;
}

.tec-tablet-paso-paso-subtitle {
    width: auto;
    height: auto;

    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    text-align: center;
}

.tec-tablet-paso-paso-cards-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

/* ////////////////////////////////////////////////// SECTION HAZ MAS ////////////////////////////////////////////////// */

.tec-tablet-haz-mas-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 744px;
    align-self: center;
    padding: 80px 64px;
    width: 100%;
    height: auto;
    gap: 48px;
    background: #1E1E1E;
}

.tec-tablet-haz-mas-title {
    font-size: 35px;
    font-weight: 600;
    line-height: 44px;
    align-self: start;
}

.tec-tablet-haz-mas-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    align-self: start;
}

/* ////////////////////////////////////////////////// SECTION SAME COMPONENTS ////////////////////////////////////////////////// */

.same-tablet-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 100px 32px;
    max-width: 744px;
    align-self: center;
    width: 100%;
    height: auto;
    gap: 80px;
}

.same-tablet-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    gap: 32px;

    width: 100%;
    height: auto;
}

.same-tablet-section-title {
    align-self: start;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.same-tablet-title {
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
    align-self: start;
}

.same-tablet-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    align-self: start;
}

.same-tablet-img {
    width: 595px;
    height: 607px;
}