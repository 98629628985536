.dynamic-card-tablet-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.dynamic-card-tablet-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 26px;
}

.dynamic-card-tablet-section-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.dynamic-card-tablet-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}