.numbered-img-card-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  gap: 24px;
}

.numbered-img-card-mobile-img {
  width: 318px;
}

.numbered-img-card-mobile-text-box {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.numbered-img-card-mobile-title-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.numbered-img-card-mobile-number-text {
  font-size: 35px;
  font-weight: 600;
  line-height: 44px;
  color: #45D7A2;
}

.numbered-img-card-mobile-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: #FFFFFF;
  align-self: center;
}

.numbered-img-card-mobile-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
}