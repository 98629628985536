/* ////////////////////////////////////////////////// FOOTER ////////////////////////////////////////////////// */

.footer-tablet-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 70px 30px;

  width: 100%;
  height: auto;
  max-width: 744px;
  align-self: center;
}

.footer-tablet-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px;

  gap: 40px;

  width: auto;
  height: auto;
}

.footer-tablet-img-box {
  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer-tablet-logo {
  width: 180px;
  height: 35px;
}

.footer-tablet-pages {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: auto;
  height: auto;
  gap: 240px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.footer-tablet-pages-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;

  height: auto;

  flex: none;
  flex-grow: 0;
}

.footer-tablet-column-01 {
  order: 0;
}

.footer-tablet-column-02 {
  order: 1;
}

.footer-tablet-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;

  width: auto;
  height: 28px;
}

.footer-tablet-social-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 36px;
  height: 36px;

  background: #000000;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.footer-tablet-social-img {
  width: 36px;
  height: 36px;
}

.footer-tablet-copyright-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 45px;

  width: auto;
  height: auto;

  flex: none;
  order: 3;
  flex-grow: 0;
}

.footer-tablet-divider {
  width: 616px;
  height: 0px;

  border: 1px solid #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer-tablet-copyright-text {
  width: 568px;
  height: auto;

  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  flex: none;
  order: 1;
  flex-grow: 0;
}