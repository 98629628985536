.seguridad-container {
  width: 100%;
  height: auto;

  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}

/* ////////////////////////////////////////////////// HEADER ////////////////////////////////////////////////// */

.header {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;

  padding: 25px 124px;
  max-width: 1440px;
  align-self: center;

  width: 100%;
  height: 118px;
  z-index: 900;
  background: #000000;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  width: 177.99px;
  height: 34.61px;
}

.header-logo-link {
  width: auto;
  height: auto;
}

.header-nav-list {
  display: flex;
  align-items: center;
  gap: 24px;

  width: auto;
  height: 68px;
}

.header-nav-views {
  display: flex;
  align-items: center;
  width: auto;
  height: 28px;
  gap: 28px
}

.header-link {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.header-btn-box {
  display: flex;
  align-items: center;

  width: auto;
  height: auto;
}

.header-btn {
  width: auto;
  height: auto;

  box-sizing: border-box;

  padding: 20px 36px;
  cursor: pointer;
  background: #45D7A2;
  color: #000000;
  border-radius: 40px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  text-align: center;
}

.header-lang-box {
  flex: none;
  flex-grow: 0;
  order: 2;
}

.header-link-active {
  font-weight: 700;
}

/* ////////////////////////////////////////////////// SECTION ////////////////////////////////////////////////// */

.seguridad-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1440px;
  align-self: center;
  margin-top: 10vh;
  padding: 100px 110px;
  gap: 50px;
}

.seguridad-image {
  width: 100%;
  height: auto;
}

.seguridad-title {
  width: 100%;
  height: auto;

  font-weight: 700;
  font-size: 50px;
  line-height: 76px;

  align-self: center;
  text-align: center;
}

.seguridad-subtitle {
  width: 100%;
  height: auto;

  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  align-self: center;
  text-align: start;
}

.seguridad-text {
  width: 100%;
  height: auto;

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  align-self: center;
  text-align: start;
}

.seguridad-self-start {
  width: 100%;
  align-self: center;
}