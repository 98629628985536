.contacto-container {
  width: 100%;
  height: auto;

  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}

/* ////////////////////////////////////////////////// HEADER ////////////////////////////////////////////////// */

.header {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;

  padding: 25px 124px;
  max-width: 1440px;
  align-self: center;
  width: 100%;
  height: 118px;
  z-index: 900;
  background: #000000;
  top: 0;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  width: 177.99px;
  height: 34.61px;
}

.header-logo-link {
  width: auto;
  height: auto;
}

.header-nav-list {
  display: flex;
  align-items: center;
  gap: 24px;

  width: auto;
  height: 68px;
}

.header-nav-views {
  display: flex;
  align-items: center;
  width: auto;
  height: 28px;
  gap: 28px
}

.header-link {
  width: auto;
  height: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.header-btn-box {
  display: flex;
  align-items: center;

  width: auto;
  height: auto;
}

.header-btn {
  width: auto;
  height: auto;

  box-sizing: border-box;

  padding: 20px 36px;
  cursor: pointer;
  background: #45D7A2;
  color: #000000;
  border-radius: 40px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  text-align: center;
  transition: background 0.25s ease-in;
}

.header-btn:hover {
  color: #FFF;
  background: #465DFF;
  transition: background 0.25s ease-in;
}

.header-lang-box {
  flex: none;
  flex-grow: 0;
  order: 2;
}

.header-link-active {
  font-weight: 700;
}

/* ////////////////////////////////////////////////// CONTACT ////////////////////////////////////////////////// */

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin-top: 11vh;
  padding: 80px 124px;
  max-width: 1440px;
  align-self: center;
  gap: 100px;
  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;

  gap: 20px;
  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-title {
  width: auto;
  height: auto;

  font-weight: 700;
  font-size: 50px;
  line-height: 76px;
  text-align: center;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-text {
  width: auto;
  height: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-content {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  gap: 5%;
  width: 100%;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-img-box {
  width: 47.5%;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-img {
  width: 100%;
  height: auto;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 32px;
  width: 47.5%;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-form-input-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 2%;
  width: 100%;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-form-input-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 24px;
  width: 49%;
  height: auto;

  flex: none;
  flex-grow: 0;
}

.contact-form-input-column-01 {
  order: 0;
}

.contact-form-input-column-02 {
  order: 1;
}

.contact-form-input-01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 25px;
  width: auto;
  height: auto;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-form-input-02 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 25px;
  width: auto;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-form-label {
  width: auto;
  height: 28px;

  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.contact-form-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 30px;
  width: 100%;
  height: auto;

  background: #1E1E1E;

  border: 1px solid #D0D0D0;
  border-radius: 50px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-form-input[type=text]:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contact-form-input-success {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 30px;
  width: 100%;
  height: auto;

  background: #1E1E1E;

  border: 1px solid #45D7A2;
  border-radius: 50px;
  outline: none;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-form-input-success:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contact-form-input-error {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 30px;
  width: 100%;
  height: auto;

  background: #1E1E1E;

  border: 1px solid #FF2159;
  border-radius: 50px;
  outline: none;


  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-form-input-error:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contact-textarea-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 25px;
  width: 100%;
  height: auto;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-form-textarea {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;

  background: #1E1E1E;
  border: 1px solid #D0D0D0;
  border-radius: 20px;
  outline: none;
}

.contact-form-textarea:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contact-form-textarea-success {
  box-sizing: border-box;
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;
  
  background: #1E1E1E;
  border: 1px solid #45D7A2;
  border-radius: 20px;
  outline: none;
}

.contact-form-textarea-success:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contact-form-textarea-error {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px 30px 100px;
  width: 100%;
  min-height: 148px;

  background: #1E1E1E;
  border: 1px solid #FF2159;
  border-radius: 20px;
  outline: none;
}

.contact-form-textarea-error:focus {
  outline: none;
  border: 1px solid #FEC40B;
}

.contact-btn-box {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 162px;
  width: auto;
  height: 68px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.contact-btn {
  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  cursor: pointer;

  padding: 20px 36px;
  gap: 8px;

  width: auto;
  height: 68px;

  background: #FEC40B;
  border: 1px solid #000000;
  border-radius: 40px;
  transition: background 0.25s ease-in;
}

.contact-btn:hover {
  background: #EC632B;
  border: 1px solid #EC632B;
  transition: background 0.25s ease-in;
}

.contact-btn:hover .contact-btn-text {
  color: #FFF;
}

.contact-btn-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

.contact-btn:disabled {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;

  padding: 20px 36px;
  gap: 8px;

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  width: 100%;
  height: 68px;
  color: var(--color-dark-black-20, #4D4D4D); 

  border-radius: 40px;
  border: 1px solid var(--color-dark-black-40, #3C3C3C);
  background: var(--color-dark-black-60, #1E1E1E);
}

.contact-btn:disabled:hover span {
  color: #000; 
}