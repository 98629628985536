.dynamic-header-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 76px;
  max-height: 50px;
}

.dynamic-header-mobile-title {
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  max-height: 35px;
}