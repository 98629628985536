.backdrop {
  display: flex;
  position: fixed;
  justify-content: center;
  align-content: flex-end;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.75);
}

.list-container {
  display: flex;
  justify-content: center;
  align-self: end;
  width: 100%;
  background: #1E1E1E;
  border-radius: 25px 25px 0 0;
  padding: 25px 25px 0;
}

.custom-list {
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.first-item {
  width: 30px;
  height: 2px;
  border-radius: 6px;
  background: #FFF;
  align-self: center;
  margin-bottom: 25px;
}